export const useSlideOverStore = defineStore('slideOver', () => {
  const slideOverType = ref('')
  const slideOverActive = ref(false)
  const slideOverData = ref({})
  const slideOverOnClose = ref(null)

  function openSlideover(type: string, data: any, onClose: any = null) {
    slideOverData.value = data
    slideOverType.value = type
    slideOverActive.value = true
    slideOverOnClose.value = onClose
  }

  function closeSlideover() {
    if (slideOverOnClose.value) slideOverOnClose.value()
    slideOverActive.value = false
    slideOverData.value = {}
    slideOverType.value = ''
    slideOverOnClose.value = null
  }

  return {
    slideOverType,
    slideOverActive,
    slideOverData,
    slideOverOnClose,
    openSlideover,
    closeSlideover,
  }
})
